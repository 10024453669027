/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import tw from 'twin.macro'
import styled from '@emotion/styled'

import Header from './header'
import Footer from './footer'
import '../sass/globals.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const PageWrap = styled.div`
    ${tw`flex flex-col`}
    min-height: 100vh;
  `

  const Main = styled.main`
    ${tw`flex-grow`}
  `

  return (
    <PageWrap>
      <Global
        styles={css`
          body {
            ${tw``}
            letter-spacing: 0.025em;
          }
          h1 {
            ${tw`max-w-screen-md mx-auto mt-8 mb-5
            text-2xl md:text-3xl font-medium`}
          }
          h2 {
            ${tw`mt-12 md:mt-10 mb-2 relative inline-block z-20
            text-xl`}
          }
          h3 {
            ${tw`mt-4 font-bold`}
            &:after {
              ${tw`bg-secondary`}
              top: 0.65rem;
            }
          }
          h1,
          h2 {
            ${tw`font-display`}
          }
          p {
            ${tw`mb-2`}
          }

          ul {
            ${tw`list-outside list-disc pl-4`}
            li {
              ${tw`mb-3`}
            }
          }
        `}
      />

      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer />
      {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
    </PageWrap>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
