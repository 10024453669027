import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { breakpoints } from '../utils/styles'

const Wrap = styled.div`
  ${tw`fixed items-center justify-center top-0 h-full w-full md:h-auto md:w-auto md:relative p-10 md:p-0 md:mr-4
  text-center text-white md:text-black md:bg-transparent z-40`}
  background: #222;
`

const MenuWrap = styled.nav`
  a {
    ${tw`block md:inline-block md:px-5 py-5 md:py-2 uppercase font-display relative
    text-4xl md:text-base md:font-sans font-semibold`}

    span {
      @media (min-width: ${breakpoints.md}px) {
        ${tw`block border-b-2 border-white`}
      }
    }

    &.active,
    &:hover {
      span {
        @media (min-width: ${breakpoints.md}px) {
          ${tw`block border-b-2 border-black`}
        }
      }
    }
  }
`

const Menu = props => {
  const menuItems = [
    { label: 'Work', uid: '/work/' },
    { label: 'Resume', uid: '/resume/' },
    { label: 'About', uid: '/about/' },
  ]

  const { menuIsOpen } = props

  return (
    <Wrap className={`${!menuIsOpen ? 'hidden md:block' : 'flex'}`}>
      <MenuWrap>
        {menuItems.map((item, key) => (
          <Link
            to={item.uid}
            title={item.label}
            key={key}
            partiallyActive
            activeClassName="active"
          >
            <span>{item.label}</span>
          </Link>
        ))}
      </MenuWrap>
    </Wrap>
  )
}

Menu.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
}

export default Menu
