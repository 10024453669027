import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import tw from 'twin.macro'

const Wrap = styled.footer`
  ${tw`py-10 md:p-4 text-center md:flex mt-20`}
  background-color: #F5F5F5;

  span {
    a {
      ${tw`block md:inline-block md:px-2 md:mx-2 py-4 md:py-2 text-xs`}
      color: #986461;

      &.active,
      &:hover {
        ${tw`border-primary underline`}
      }
    }
  }
`

const Menu = styled.nav`
  ${tw`mx-auto`}
`

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      prismicFooterMenu {
        data {
          menu_item {
            uid
          }
        }
      }
    }
  `)

  return (
    <Wrap>
      <Menu>
        {data.prismicFooterMenu.data.menu_item.map((item, key) => (
          <span key={key} dangerouslySetInnerHTML={{ __html: item.uid }} />
        ))}
      </Menu>
    </Wrap>
  )
}

export default Footer
