import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Rotate as Hamburger } from 'hamburger-react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import Menu from './menu'

const Wrap = styled.header`
  ${tw`text-left flex items-center justify-between mx-auto md:p-5 mb-5`}
  max-width: 1400px;
  width: 100%;
`

const Title = styled.div`
  ${tw`inline-block p-6 px-0 ml-5 md:px-4 uppercase font-display leading-none relative z-20`}
`

const HamburgerWrap = styled.button`
  ${tw`absolute top-0 mt-2 mr-2 right-0 z-50 md:hidden`}
  .is-open {
    ${tw`text-white`}
  }
`

export default function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    if (!menuIsOpen) {
      enableBodyScroll()
    } else {
      disableBodyScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen])

  return (
    <Wrap>
      <Title>
        <Link to="/">Emma Tofft</Link>
      </Title>
      <HamburgerWrap>
        <Hamburger
          toggled={menuIsOpen}
          toggle={setMenuIsOpen}
          size={20}
          color={`${menuIsOpen ? '#FFFFFF' : '#222222'}`}
        />
      </HamburgerWrap>
      <Menu menuIsOpen={menuIsOpen} />
    </Wrap>
  )
}
